import { createTheme } from "@xcira/components";
import { ThemeOptions } from "@xcira/components";
import { CSSProperties } from "react";
import { Theme } from "@xcira/components";

declare module "@mui/material/styles" {
  interface Sizes {
    [key: string]: string | number;
  }

  interface Shape {
    borderRadius: number;
  }

  interface Palette {
    extra: {
      lightBlack: string;
      highlight: string;
    };
  }

  interface PaletteOptions {
    extra: {
      lightBlack: string;
      highlight: string;
    };
  }

  interface Theme {
    sizes: Sizes;
    shape: Shape;
    palette: Palette;
  }

  interface ThemeOptions {
    sizes: Sizes;
    shape: Shape;
    palette?: PaletteOptions;
    mode?: Mode;
  }

  interface Mode {
    <L, D>(light: L, dark: D): L | D;
  }
}

const black = "#222222";
const black2 = "#00000026";
const black3 = "rgba(0,0,0,0.04)";

const blue = "#1D619E";
const blue2 = "#0564B6";

const gray0 = "#EEEEEE";
const gray1 = "#D8D8D8";

const red = "#D32F2F";

const white = "#FFFFFF";

const yellow = "#FFCD00";
const yellow2 = "#ED6C02";

export const SPACING = 8;

const sharedTheme: ThemeOptions = {
  shape: {
    borderRadius: 8,
  },
  spacing: SPACING,
  typography: {
    fontFamily: "Roboto",
    body2: {
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1367,
      xl: 1920,
    },
  },
  sizes: {
    appDrawerWidth: 412,
  },
  components: {},
};

const muiLightTheme = createTheme(sharedTheme, {
  mode: <L>(light: L) => light,
  palette: {
    common: {
      black: black,
    },
    background: {
      default: white,
      paper: white,
    },
    grey: {
      50: gray0,
      100: gray1,
    },
    text: {
      primary: black,
    },
    primary: {
      main: blue,
      dark: "#174E7E",
      light: "#D2DFEC",
      contrastText: white,
      hover: blue,
    },
    secondary: {
      main: "#5BB3DA",
      dark: "#498FAE",
      light: "#DEF0F8",
      contrastText: white,
      hover: "#5BB3DA",
    },
    info: {
      main: blue2,
    },
    warning: {
      main: yellow,
      dark: yellow2,
    },
    extra: {
      lightBlack: black2,
      highlight: black3,
    },
    error: {
      main: red,
    },
  },
});

const muiDarkTheme = createTheme(sharedTheme, {
  mode: <L, D>(_light: L, dark: D) => dark,

  palette: {
    common: {
      black: black,
    },
    background: {
      default: white,
      paper: white,
    },
    grey: {
      50: gray0,
      100: gray1,
    },
    text: {
      primary: black,
    },
    primary: {
      main: blue,
      dark: "#174E7E",
      light: "#D2DFEC",
      contrastText: white,
      hover: blue,
    },
    secondary: {
      main: "#5BB3DA",
      dark: "#498FAE",
      light: "#DEF0F8",
      contrastText: white,
      hover: "#5BB3DA",
    },
    info: {
      main: blue2,
    },
    warning: {
      main: yellow,

      dark: yellow2,
    },
    extra: {
      lightBlack: black2,
      highlight: black3,
    },
  },
});

export const appThemes = {
  light: muiLightTheme,
  dark: muiDarkTheme,
};

export type AppTheme = keyof typeof appThemes;

export const createOpeningTransition = (theme: Theme, props: string | string[]): string =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });

export const createClosingTransition = (theme: Theme, props: string | string[]): string =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  });

export const openedMixin = (theme: Theme): CSSProperties => ({
  width: theme.sizes.appDrawerWidth,
  transition: createOpeningTransition(theme, "width"),
  overflow: "visible",
  position: "unset",
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
});

export const closedMixin = (theme: Theme): CSSProperties => ({
  width: theme.spacing(10),
  transition: createClosingTransition(theme, "width"),
  overflowX: "hidden",
  position: "unset",
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
});
